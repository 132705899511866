import { forwardRef } from "react";
import stringUtil from "../../utils/string.util";

export default forwardRef(
  ({ readonly, value, disabled, rows, onChange }, ref) => {
    if (readonly) {
      return (
        <label
          className="form-control-plaintext"
          dangerouslySetInnerHTML={{ __html: stringUtil.textToHtml(value) }}
        ></label>
      );
    } else {
      return (
        <textarea
          ref={ref}
          className="form-control"
          rows={rows}
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value, e)}
        />
      );
    }
  }
);
