import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MessageContext = createContext(null);

export const useMessage = () => {
  return useContext(MessageContext);
};

const showToast = (type, message, ms = 3000) => {
  toast(message, {
    position: "bottom-right",
    autoClose: ms,
    hideProgressBar: true,
    type,
  });
};

export const MessageContextProvider = ({ children }) => {
  const showSuccess = (message, ms) => {
    showToast("success", message, ms);
  };

  const showWarning = (message, ms) => {
    showToast("warning", message, ms);
  };

  const showError = (message, ms) => {
    showToast("error", message, ms);
  };

  return (
    <MessageContext.Provider value={{ showSuccess, showWarning, showError }}>
      <>
        {children}
        <ToastContainer theme="colored" />
      </>
    </MessageContext.Provider>
  );
};
